const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const shortDate = dateString => {
	const date = new Date(dateString);
	const month = months[date.getMonth()].substring(0, 3);
	return `${date.getDate()} ${month}`;
};

export default shortDate;
