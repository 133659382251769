import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticleLink from "../components/UI/ArticleLink";
import shortDate from "../shortDate";

import "./articles.css";

const isDev = process.env.NODE_ENV === "development";
const onlyShowLive = true;

const IndexPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				allMarkdownRemark {
					edges {
						node {
							frontmatter {
								date
								hidden
								tags
								title
								summary
								seriesPart
								seriesName
							}
							fields {
								relativeRoute
								pageName
							}
						}
					}
				}
			}
		`
	);

	const articlesByYear = useMemo(() => {
		// Flatten the data for easy parsing
		let allArticles = data.allMarkdownRemark.edges.map(x => ({
			...x.node.frontmatter,
			...x.node.fields,
		}));

		// Don't include unpublished articles except in development mode
		if (!isDev || onlyShowLive) {
			allArticles = allArticles.filter(
				x =>
					x.hidden === false &&
					!!x.relativeRoute &&
					x.relativeRoute.startsWith("/articles/4_published")
			);
		}

		// Ignore everything in the /articles
		allArticles = allArticles.filter(
			x =>
				x.hidden === false &&
				!!x.relativeRoute &&
				!x.relativeRoute.startsWith("/articles/0_ignore")
		);

		// Split out into years
		let articlesByYear = {};
		allArticles.forEach(x => {
			const year = new Date(x.date).getFullYear();
			if (!articlesByYear[year]) {
				articlesByYear[year] = [];
			}
			articlesByYear[year].push(x);
		});

		// Sort by published date (newest first)
		const allYears = Object.keys(articlesByYear).sort();
		allYears.forEach(year => {
			articlesByYear[year] = articlesByYear[year].sort(
				(a, b) => new Date(b.date) - new Date(a.date)
			);
		});

		return articlesByYear;
	}, [data]);

	return (
		<Layout>
			<SEO title="Home" />
			<div className="container mx-auto px-6">
				<h1 className="text-3xl">All articles</h1>
				{Object.keys(articlesByYear)
					.sort((a, b) => b - a)
					.map(year => (
						<div className="mt-6" key={year}>
							<h2 className="text-2xl">{year}</h2>
							<table className="articlesTable mt-2">
								<tbody>
									{articlesByYear[year].map(x => (
										<tr key={x.title + x.date}>
											<td className="p-2">
												{shortDate(x.date)}
											</td>
											<td>
												<ArticleLink {...x} />
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					))}
			</div>
		</Layout>
	);
};

export default IndexPage;
