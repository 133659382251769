import React from "react";
import { Link } from "gatsby";
import prettyDate from "../../prettyDate";
import { getLocalStorage } from "../../gatsbySafe";

const ArticleLink = ({
	title,
	summary,
	seriesPart,
	pageName,
	className = "",
}) => {
	const url =
		seriesPart === 0 ? `/series/${pageName}/` : `/article/${pageName}/`;

	const lsData = getLocalStorage().getItem("articleLastRead:" + pageName);
	const lastRead = lsData ? new Date(lsData) : null;

	const arData = getLocalStorage().getItem("articleProgress:" + pageName);
	const progress = arData ? parseFloat(arData) : null;

	const message = React.useMemo(() => {
		if (!lastRead || !progress) {
			return null;
		}

		if (progress >= 100) {
			return (
				<div className="mt-1.5 text-xs text-green-700 dark:text-green-400 max-w-lg">
					<svg
						className="w-4 h-4 inline pb-0.5"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M5 13l4 4L19 7"
						/>
					</svg>
					You finished reading this on {prettyDate(lastRead)}.
				</div>
			);
		}

		return (
			<div className="mt-1.5 text-xs text-orange-800 dark:text-orange-300 max-w-lg">
				<svg
					className="w-4 h-4 inline pb-0.5"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M6 18L18 6M6 6l12 12"
					></path>
				</svg>
				You started reading this on {prettyDate(lastRead)}, but you
				didn't finish.
			</div>
		);
	}, [lastRead, progress]);

	return (
		<div className={className}>
			<Link to={url}>
				<h2 className="text-lg text-teal-800 dark:text-teal-400">
					{title}
				</h2>
				<div className="text-sm text-gray-800 dark:text-gray-100 max-w-lg">
					{summary}
				</div>
				{message}
			</Link>
		</div>
	);
};

export default ArticleLink;
